<template>
	<div class="download">
		<img
			class="logo"
			src="../assets/images/icon_cumenlogo.png"
			alt="logo"
		/>
		<img class="name" src="../assets/images/app-name.png" alt="app-name" />
		<img class="desc" src="../assets/images/download-main.png" alt="desc" />
		<span class="slogan">粗门，就是好玩！</span>
		<div class="btn" @click="download">立即下载</div>
		<!-- 引导默认浏览器打开网页 -->
		<guide-app v-model:showGuide="showGuide" />
	</div>
</template>

<script>
import GuideApp from "@/components/GuideApp.vue";
export default {
	name: "Download",
	components: {
		GuideApp
	},
	data() {
		return {
			showGuide: false,
			isWechatBrowser: false,
			isIos: false,
			isAndroid: false,
		};
	},
	created() {
		const { userAgent } = window.navigator;
		this.isWechatBrowser =
			userAgent.toLowerCase().indexOf("micromessenger") !== -1;
		this.isIos = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
		this.isAndroid =
			userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;
		/* 默认打开引导浏览器提示的场景【设备：安卓/IOS，环境：微信浏览器】 */
		this.showGuide = this.isWechatBrowser;
	},
	methods: {
		download() {
			/**
			 * 下载规则
			 * 微信内: 	
			 * 		[ios] 弹出打开默认浏览器的弹框，引导使用默认浏览器打开网页
			 * 	[android] 弹出打开默认浏览器的弹框，引导使用默认浏览器打开网页
			 * 微信外:     
			 * 		[ios] 不弹打开默认浏览器的弹框，点击下载，打开 https://apps.apple.com/app/%E7%B2%97%E9%97%A8/id1593823623
			 * 	[android] 不弹打开默认浏览器的弹框，点击下载，打开 market://details?id=tech.xiaoxiang.emily
			 */
			if(this.isWechatBrowser) this.showGuide = true;
			else {
				location.href = this.isIos 
					? "https://apps.apple.com/app/%E7%B2%97%E9%97%A8/id1593823623" 
					: "https://sj.qq.com/appdetail/tech.xiaoxiang.emily";
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.download {
	position: relative;
	@include column(flex-start, center);
	> .logo {
		width: px2rem(130);
		height: px2rem(130);
		margin-top: px2rem(100);
	}
	> .name {
		width: px2rem(130);
		height: px2rem(60);
		margin-top: px2rem(20);
	}
	> .desc {
		width: 100%;
		margin-top: px2rem(42);
	}
	> .slogan {
		color: #222;
		font-size: px2rem(40);
		line-height: px2rem(54);
		font-family: AlibabaPuHuiTiH;
	}
	> .btn {
		margin: px2rem(32);
		width: calc(100% - px2rem(64));
		height: px2rem(88);
		line-height: px2rem(88);
		color: #1efa91;
		text-align: center;
		font-size: px2rem(32);
		background-color: #222;
		border-radius: px2rem(48);
		font-family: AlibabaPuHuiTiH;
	}
	> .guide {
		@include row(flex-end, flex-start);
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.3);
		> img {
			width: 100%;
			margin-top: px2rem(-30);
			margin-right: px2rem(-30);
		}
	}
}
</style>
